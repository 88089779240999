import React, { Fragment } from 'react';
import { navigate } from 'gatsby';
import { H2, H4, FadeInSection, NormalButton, RightArrowIcon, InputText } from '../../';
import { SECTION_IDS } from '../../../constants';
import { InsightsSecondType } from '../../../utils/globalTypes';
import '../Service/index.scss';
import './index.scss';
import RenderImage from '../../images';
import {
  Box,
  Breadcrumbs,
  Button,
  ButtonGroup,
  Chip,
  Divider,
  Grid,
  InputAdornment,
  Typography,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

interface Props {
  content: InsightsSecondType[];
}

const InsightsSecondSection = ({ content }: Props) => {
  const [data, setData] = React.useState(content);
  const [searchTerm, setSearchTerm] = React.useState('');

  const setFilteredData = (tipo: string) => {
    if (tipo === 'all') {
      setData(content); // Mostrar todos los datos
    } else {
      const filtered = content.filter((item) => item.type === tipo);
      setData(filtered);
    }
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = event.target.value;
    setSearchTerm(searchTerm);

    const filteredData = content.filter((item) => item.title.toLowerCase().includes(searchTerm.toLowerCase()));
    setData(filteredData);
  };

  const onLearnMore = (url: string) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);

    // Extrae las partes de la fecha en UTC
    const year = date.getUTCFullYear();
    const month = date.getUTCMonth(); // Devuelve el mes en base 0

    // Mapeo de los meses
    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];

    // Formatea la salida
    const formattedDate = `${monthNames[month]} ${year}`;
    console.log(formattedDate, date);

    return formattedDate;
  };
  return (
    <section id={SECTION_IDS.second} className="section-space flex flex-column items-center">
      <div className="container">
        <div className="hideIns">
          <p style={{ fontFamily: 'CustomLato, Trebuchet MS, sans-serif', fontSize: '22px', fontWeight: 'bold' }}>
            FILTER
          </p>
          <div>
            <ButtonGroup variant="text" style={{ padding: '6px 8px !important' }}>
              <Button className="MuiButton-text-ins" onClick={() => setFilteredData('all')}>
                All
              </Button>
              <Button className="MuiButton-text-ins" onClick={() => setFilteredData('Business Services')}>
                Business Services
              </Button>
              <Button className="MuiButton-text-ins" onClick={() => setFilteredData('Industrial')}>
                Industrial
              </Button>
              <Button className="MuiButton-text-ins" onClick={() => setFilteredData('Consumer')}>
                Consumer
              </Button>
            </ButtonGroup>
          </div>
        </div>
        <InputText
          label="SEARCH INSIGHTS"
          variant="filled"
          className="input-field ins-size"
          value={searchTerm}
          onChange={handleSearch}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <svg width="33" height="29" viewBox="0 0 33 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="21.5" cy="11.5" r="9" stroke="black" stroke-width="5" />
                  <line x1="2.38514" y1="27.0915" x2="15.3851" y2="16.0915" stroke="black" stroke-width="5" />
                </svg>
              </InputAdornment>
            ),
          }}
        />
      </div>
      <div className="flex insights-list">
        {data.length === 0 ? (
          <Chip color="secondary" label="No data found" style={{ marginTop: 50 }} />
        ) : (
          data.map((data, index) => {
            return (
              <Fragment key={`insights-${index}`}>
                <div className="service-item">
                  <div className="img-container relative" style={{ marginBottom: '10px' }}>
                    <RenderImage
                      imgUrl={data.image.asset.url}
                      gatsbyImg={data.image.asset.gatsbyImageData}
                      style={'first-image'}
                    />
                  </div>
                  <div className="flex-1" style={{ marginBottom: '10px', width: '70%' }}>
                    <p style={{ fontFamily: 'Cambon', fontSize: '24px' }}>{data.title}</p>
                  </div>
                  {/* <div className="service-description steer-body">
                  <FadeInSection timeout={100}>
                    
                  </FadeInSection>
                </div> */}
                  <NormalButton
                    type="normal"
                    onClick={() => onLearnMore(data.pdf.asset.url)}
                    label="DOWNLOAD INSIGHTS"
                  />
                  <div className="flex justify-between">
                    <p
                      style={{
                        fontFamily: 'CustomLato, Trebuchet MS, sans-serif',
                        fontSize: '15px',
                      }}
                    >
                      {formatDate(data.date).toUpperCase()}
                    </p>
                  </div>
                </div>
              </Fragment>
            );
          })
        )}
      </div>
    </section>
  );
};

export default InsightsSecondSection;
