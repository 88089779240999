export const insightsQuery = `// groq
*[_type == "insights"][0]{
  seo {
    description,
    keywords,
    title
  },
  firstSection {
    title,
    image {
      asset->{
        url
      }
    }
  },
  items[]{
    title,
    pdf {
      asset->{
        url
      }
    },
    image {
      asset->{
        url
      }
    },
    date,
    type
  }
}
`;
