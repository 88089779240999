import React, { Fragment, useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import { SanityAboutType, SanityInsightsType } from '../utils/globalTypes';
import { SEO } from '../components';
import { SEO_TEXT } from '../constants';
import { getClient } from '../utils/sanity.client';
import { insightsQuery } from '../utils/querys/insights/query';
import InsightsFirstSection from '../components/Sections/insights/firstSection';
import InsightsSecondSection from '../components/Sections/insights/secondSection';

interface Props {
  data: {
    sanityInsights: SanityInsightsType;
  };
}

const InsightsPage = ({ data: { sanityInsights } }: Props) => {
  const [aboutData, setAboutData] = useState<any>();

  useEffect(() => {
    const url = new URL(location.href);
    const getData = async () => {
      const client = getClient({ token: process.env.GATSBY_SANITY_READ_TOKEN || '' });
      const data = await client.fetch(insightsQuery);
      setAboutData(data);
    };
    if (aboutData === undefined && url.search === '?preview_mode=true') getData();
  }, [aboutData]);
  return (
    <Fragment>
      <SEO title={sanityInsights.seo?.title || ''} description={sanityInsights.seo?.description || ''} />
      <InsightsFirstSection content={aboutData ? aboutData.firstSection : sanityInsights.firstSection} />
      <InsightsSecondSection content={aboutData ? aboutData.items : sanityInsights.items} />
    </Fragment>
  );
};

export default InsightsPage;
export const pageQuery = graphql`
  query {
    sanityInsights {
      seo {
        description
        keywords
        title
      }
      firstSection {
        title
        image {
          asset {
            gatsbyImageData(fit: CROP)
          }
        }
      }
      items {
        title
        pdf {
          asset {
            url
          }
        }
        image {
          asset {
            gatsbyImageData(fit: CROP)
          }
        }
        date
        type
      }
    }
  }
`;
